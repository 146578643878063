import { IoIosClose, IoIosSearch } from "react-icons/io";
import { IoOptionsOutline } from "react-icons/io5";
import NotificationsCard from "./NotificationsCard";
import NotificationService from "../../services/notification/NotificationService";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollingLoader from "../atom/loader/scrollingLoader";
import Loader from "../atom/loader/Loader";
import { SearchIcon } from "../icons/SearchIcon";
import { HiChevronUpDown } from "react-icons/hi2";
import { NEWEST_FIRST, OLDEST_FIRST } from "../../utils/constants";
import { CalendarIcon } from "../icons/Icons";
export default function NotificationsContainer() {
  const {
    notificationList,
    notificationStatusUpdate,
    getlistNotificationApi,
    loadingNotification,
    clearNotificationState,
    notificationListDetails,
    totalNotification,
    unReadNotificationTotal,
    ArchieveNotificationTotal,
  } = NotificationService((state) => state);
  const [loadingState, setLoadingState] = useState(true);
  const [activeTab, setActiveTab] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(2);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [sortOrder, setSortOrder] = useState(NEWEST_FIRST);
  const changeSortOrder = (order) => {
    setSortOrder(order);
    setShowSortDropdown(false);
  };

  const toggleSortDropdown = () => setShowSortDropdown(!showSortDropdown);
  const getInfiniteScrollNotification = (filter) => {
    const notificationData = {
      page: filter ? 1 : currentPage,
      businessId: "",
      start_date: "",
      end_date: "",
      is_archived: filter == "archive" ? true : false,
      ...(filter == "unread" && { is_read: filter == "unread" ? false : "" }),
      limit: "",
    };
    getlistNotificationApi(notificationData, filter).then((data) => {
      setLoadingState(false);
      if (filter) {
        setCurrentPage(2);
      } else {
        setCurrentPage(currentPage + 1);
      }
    });
  };

  const getNotification = async (filter) => {
    await clearNotificationState();
    await getInfiniteScrollNotification(filter);
  };
  const handleSearchClick = () => {
    setIsInputOpen((prevState) => !prevState);
  };
  const handleIsReadable = async (id, event) => {
    if (event || !event) {
      setCurrentPage(1);
      await notificationStatusUpdate(
        {
          id: id,
          notifcationDetails: { is_read: event },
        },
        "is_read"
      );
      getNotification(activeTab);
    }
  };
  const handleArchived = async (id, event) => {
    if (event || !event) {
      setCurrentPage(1);
      await notificationStatusUpdate(
        {
          id: id,
          notifcationDetails: { is_archived: event },
        },
        "is_archived"
      );
      getNotification(activeTab);
    }
  };
  useEffect(() => {
    getlistNotificationApi().then((data) => {
      setLoadingState(false);
    });
  }, []);

  return (
    <div className="sticky flex flex-col items-center justify-center w-full !h-[calc(100dvh-143px)]  text-sm directory">
      {loadingState ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="w-full mt-6 bg-white border-[1px]  border-gray-100  overflow-y-auto dark:border-darkModeBorder rounded-lg dark:bg-darkModeMain h-full">
          <div className="w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex justify-between items-center">
            <div className="flex items-center gap-3 px-4">
              <p className="!font-bold uppercase">Inbox</p>
              <div className="h-[20px] w-[1px] bg-gray-100 dark:bg-darkModeBG mx-1"></div>
              <button
                className={
                  activeTab == "all"
                    ? "mx-1 text-blue-600 font-semibold"
                    : "mx-1 "
                }
                onClick={() => {
                  setActiveTab("all");
                  getNotification("all");
                }}
              >
                All{" "}
                <span
                  className={
                    activeTab == "all"
                      ? " text-blue-600 font-normal"
                      : " text-gray-400"
                  }
                >
                  {" "}
                  {totalNotification < 0 ? 0 : totalNotification || 0}
                </span>
              </button>
              <button
                className={
                  activeTab == "unread"
                    ? "mx-1 text-blue-600 font-semibold"
                    : "mx-1 "
                }
                onClick={() => {
                  setActiveTab("unread");
                  getNotification("unread");
                }}
              >
                Unread{" "}
                <span
                  className={
                    activeTab == "unread"
                      ? " text-blue-600 font-normal"
                      : " text-gray-400"
                  }
                >
                  {" "}
                  {unReadNotificationTotal < 0
                    ? 0
                    : unReadNotificationTotal || 0}
                </span>
              </button>
              <button
                className={
                  activeTab == "archive"
                    ? "mx-1 text-blue-600 font-semibold"
                    : "mx-1 "
                }
                onClick={() => {
                  setActiveTab("archive");
                  getNotification("archive");
                }}
              >
                Archive{" "}
                <span
                  className={
                    activeTab == "archive"
                      ? " text-blue-600 font-normal"
                      : " text-gray-400"
                  }
                >
                  {" "}
                  {ArchieveNotificationTotal < 0
                    ? 0
                    : ArchieveNotificationTotal || 0}
                </span>
              </button>
            </div>
            <div className="flex items-center justify-end  text-[13px] gap-3 mx-4 z-[999]">
              <p className="line-clamp-1">Sort by</p>
              <div className="relative flex flex-col">
                <button
                  className="w-[126px] h-[28px] rounded-full border-[1px]  border-gray-100  dark:border-darkModeBorder flex items-center !justify-center font-semibold gap-1"
                  onClick={toggleSortDropdown}
                >
                  <p>{sortOrder}</p>
                  <HiChevronUpDown />
                </button>
                {showSortDropdown && (
                  <div className="absolute w-[126px]  mt-2 bg-white border rounded-lg shadow-lg top-full dark:bg-darkModeMain dark:border-darkModeBorder">
                    <ul className=" divide-y-[1px] divide-gray-100 dark:divide-darkModeBorder w-full text-[13px] font-semibold">
                      <li>
                        <button
                          className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                          onClick={() => changeSortOrder(NEWEST_FIRST)}
                        >
                          {NEWEST_FIRST}
                        </button>
                      </li>
                      <li>
                        <button
                          className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                          onClick={() => changeSortOrder(OLDEST_FIRST)}
                        >
                          {OLDEST_FIRST}
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <p>Date</p>
              <button
                onClick={() => {}}
                className=" h-[28px] px-3 rounded-full border-[1px]  border-gray-100  dark:border-darkModeBorder flex items-center justify-center font-semibold gap-1"
              >
                <p className="capitalize flex items-center">
                  {"All"}
                  <CalendarIcon className={"w-[20px] !ml-1"} />
                </p>
              </button>
              <div className="inline-block m-3  h-[27px] min-h-[0.5em] w-0.5 self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
              {!isInputOpen && (
                <button onClick={handleSearchClick} className="">
                  <IoIosSearch size={20} />
                </button>
              )}
              {isInputOpen && (
                <div className="relative flex items-center w-[400px] z-30">
                  <SearchIcon className="w-[25px] absolute left-2 text-black dark:text-white" />
                  <input
                    type="text"
                    id="ticket-search"
                    autoComplete="off"
                    placeholder="Search business"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple"
                  />

                  <IoIosClose
                    onClick={handleSearchClick}
                    size={25}
                    className="absolute right-2"
                  />
                </div>
              )}
            </div>
          </div>

          <div
            id="scrollableDivNotification"
            className="overflow-y-auto h-[calc(100vh-240px)]"
          >
            <InfiniteScroll
              dataLength={notificationListDetails?.length || 0}
              next={getInfiniteScrollNotification}
              hasMore={currentPage <= notificationList?.meta?.last_page}
              scrollableTarget="scrollableDivNotification"
              scrollThreshold={0.5}
              style={{ overflow: "unset" }}
              loader={loadingNotification ? <ScrollingLoader /> : <></>}
            >
              {Array?.isArray(notificationListDetails) &&
                notificationListDetails?.map((notifcationDetails) => (
                  <NotificationsCard
                    handleIsReadable={(id, event) => {
                      handleIsReadable(id, event);
                    }}
                    handleArchived={(id, event) => {
                      handleArchived(id, event);
                    }}
                    notifcationDetails={notifcationDetails}
                    key={notifcationDetails?.id}
                  />
                ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
}
