import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";
import { DirectoryIcon } from "../icons/DirectoryIcon";
import { useState } from "react";
import { HiOutlineSupport } from "react-icons/hi";
import { TETooltip } from "tw-elements-react";
import { useSidebarVisibility } from "../../context/SidebarVisibilityContext";
import { AiChatApi } from "../../services/chat/AiChat";
import { FeedbackIcon } from "../icons/FeedbackIcon";
import { ChatAIIcon, KeypadIcon, Agent, Tutorial } from "../icons/Icons";
import { InboxIcon } from "../icons/InboxIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { TicketIcon } from "../icons/TicketIcon";
import FeedbackPopup from "./FeedbackPopup";
import { VITE_SIDEBAR_SUPPORT_URL } from "../../utils/constants";
import { getAccountType } from "../../routes/ProtectedRoutes";
import { extensionLogin } from "../../utils/DeviceDetails/SipDetails";
import { MdOutlineGroups } from "react-icons/md";
import { PRO_USER } from "../../utils/constants";
import { sendMixpanelEvent } from "../../services/mixpanelService";
import TourGuide from "../TourGuide";
import { useUserStore } from "../../services/tourGuideServices";
import CallPopup from "../calls/CallPopup";
import ThemeSwitcher from "../ThemeSwitcher";
import { Members } from "../icons/Icons";

export default function Sidebar() {
  const location = useLocation();
  const { setSearchViewToggle, clearSearch } = useSearch();
  const { isSidebarVisible } = useSidebarVisibility();
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [showCallPopup, setShowCallPopup] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const [onBoard, setOnBoard] = useState(false);
  const [name, setName] = useState("");
  const [startTour1, setStartTour1] = useState(false);

  const { fetchOnBoard, onboardData } = useUserStore();

  useEffect(() => {
    const fetchOnBoardData = async () => {
      const data = await fetchOnBoard();
      setName(data?.data?.data?.first_name);
    };
    fetchOnBoardData();
  }, []);

  useEffect(() => {
    if (name) {
      setStartTour1(true);
    }
  }, [name]);

  useEffect(() => {
    setOnBoard(onboardData);
  }, [onboardData]);

  const handleShowReportPopup = () => {
    setShowReportPopup(!showReportPopup);
  };

  const handleShowCallPopup = () => {
    setShowCallPopup(!showCallPopup);
  };

  const handleClose = () => {
    setShowReportPopup(false);
    setShowCallPopup(false);
  };
  const handleStartTour = () => {
    setStartTour(true);
  };

  if (!isSidebarVisible) {
    return null;
  }
  const { clearDetailState, clearAiChatState, clearState } = AiChatApi(
    (state) => state,
  );
  const handleNavClick = () => {
    clearDetailState();
    clearAiChatState();
    clearState();
    clearSearch();
    setSearchViewToggle(false); // Hide the SearchContainer
  };
  const handleClick = () => {
    setShowSupportModal(false);
  };
  document.addEventListener("click", handleClick);

  const accountType = getAccountType();
  const getClassNames = (path, currentPath, className) =>
    `${
      path === currentPath
        ? "!text-ipurple dark:!text-ipurple"
        : "!text-black dark:!text-white"
    } w-[30px] h-[30px] svg-icon dark:!text-white active:text-ipurple dark:hover:text-ipurple ${
      className || ""
    }`;
  const getClassNamesKeypad = (path, currentPath, className) =>
    `${
      path === currentPath
        ? "!text-ipurple dark:!text-ipurple"
        : "!text-black dark:!text-white"
    } svg-icon dark:!text-white active:text-ipurple dark:hover:text-ipurple pr-[2px] ${
      className || ""
    }`;
  const handleCallExtension = () => {
    const isExtensionInstalled = localStorage.getItem("isExtension");
    if (isExtensionInstalled) {
      extensionLogin();
    } else {
      setShowCallPopup(!showCallPopup);
    }
  };
  // Set options for sidebar
  const sideBarMenu = [
    { id: "1", name: "chat", icon: ChatAIIcon, link: "/chat" },
    {
      id: "2",
      name: "directory",
      icon: DirectoryIcon,
      link: "/directory/search",
    },
    { id: "3", name: "tickets", icon: TicketIcon, link: "/tickets" },
    // { id: "4", name: "calls", icon: CallIcon, link: "/calls/recent" },
    { id: "5", name: "notifications", icon: InboxIcon, link: "/notifications" },
    // accountType === PRO_USER && { id: "6", name: "teaminfo", icon: MdOutlineGroups, link: "/team-info" },
    // {
    //   id: "7",
    //   name: "Keypad",
    //   icon: KeypadIcon,

    //   link: "/",
    // },
  ];
  if (accountType === PRO_USER) {
    // In case of professional flow
    sideBarMenu.push({
      id: "6",
      name: "teaminfo",
      icon: Members,
      link: "/team-info",
    });
  }
  sideBarMenu.push({
    id: "7",
    name: "iAllo Extension",
    icon: KeypadIcon,
    link: "/",
  });

  // Dropdown menu for Support
  const sidebarSupportMenu = [
    {
      id: "theme-switcher",
      name: "ThemeSwitcher",
      component: <ThemeSwitcher />,
    },
    { id: "1", name: "Settings", icon: SettingsIcon, link: "/settings" },
    {
      id: "2",
      name: "Support",
      icon: SettingsIcon,
      link: VITE_SIDEBAR_SUPPORT_URL,
    },
    { id: "3", name: "Feedback", icon: SettingsIcon, link: "/settings" },
  ];
  const handleButtonClick = (ele) => {
    handleNavClick();

    switch (ele.id) {
      case "1":
        sendMixpanelEvent("Chat Initiated", { Type: "Search" });
        break;
      case "3":
        sendMixpanelEvent("Tickets Viewed");
        break;
      case "5":
        sendMixpanelEvent("Inbox Viewed");
        break;
      default:
        break;
    }
  };

  const currentPath = location?.pathname?.split("/")[1];

  return (
    <div className="sidebar fixed bottom-0 left-0 z-30 bg-white w-[80px] dark:bg-neutral-800 inset-y-auto flex flex-col h-[calc(100vh-65px)] max-h-[calc(100vh-65px)]">
      {startTour1 && (startTour || (!onBoard && !onboardData && name)) && (
        <TourGuide
          startTour={startTour}
          setStartTour={setStartTour}
          name={name}
        />
      )}

      <div className="flex flex-col overflow-y-auto border-r dark:border-darkModeBorder dark:bg-darkModeMain h-full">
        <nav className="h-full overflow-y-auto max-h-[calc(100vh-60px)] md:max-h-[calc(100vh-80px)] ">
          <ul role="list" className="gap-y-3 flex flex-col flex-1 h-full">
            <li className="flex-grow">
              <ul id="step-4" className="mt-4 space-y-2" role="list">
                {sideBarMenu?.map((ele) => (
                  <div className="flex" key={ele.id}>
                    <div
                      className={`${
                        (ele.name === "directory" &&
                          currentPath === "business") ||
                        currentPath === ele.name
                          ? "border-l-4 border-indigo-600 h-10 w-3 rounded-r-full"
                          : "w-3"
                      }`}
                    ></div>
                    <TETooltip placement="right" title={ele.name}>
                      {ele?.name === "iAllo Extension" ? (
                        <NavLink
                          id="step-9"
                          className={({ isActive }) =>
                            `flex p-2 text-sm font-semibold w-full justify-center rounded-md group gap-x-3 hover:text-ipurple light:bg-gray-50 light:text-gray-700 hover:dark:bg-gray-700 hover:bg-gray-50`
                          }
                          onClick={() =>
                            ele?.name === "iAllo Extension"
                              ? handleCallExtension()
                              : handleNavClick()
                          }
                        >
                          <ele.icon
                            className={`${getClassNamesKeypad(
                              ele.link?.split("/")[1],
                              currentPath,
                            )} group-hover:!text-ipurple `}
                            height={"37px"}
                            width={"37px"}
                          />
                        </NavLink>
                      ) : (
                        <NavLink
                          to={ele.link}
                          className={({ isActive }) =>
                            `flex p-2 mr-2 text-sm font-semibold w-full justify-center rounded-md group gap-x-3  ${
                              isActive || currentPath === ele.name
                                ? "text-ipurple"
                                : "hover:text-ipurple light:bg-gray-50 light:text-gray-700 hover:dark:bg-gray-700 hover:bg-gray-50"
                            }`
                          }
                          onClick={() => {
                            handleButtonClick(ele);
                          }}
                        >
                          <ele.icon
                            className={`${getClassNames(
                              ele.link?.split("/")[1],
                              currentPath,
                            )} group-hover:!text-ipurple`}
                          />
                        </NavLink>
                      )}
                    </TETooltip>
                  </div>
                ))}
              </ul>
            </li>
            {sidebarSupportMenu.map((ele) => (
              <div className="flex" key={ele.id}>
                <div
                  className={`${
                    currentPath === ele.name
                      ? "border-l-4 border-indigo-600 h-10 w-3 rounded-r-full"
                      : "w-3"
                  }`}
                ></div>
                {ele.name === "ThemeSwitcher" ? (
                  <div className="w-full flex justify-center mr-2.5 mb-2 hover:text-ipurple  rounded-md  ">
                    {ele.component}
                  </div>
                ) : ele.name === "Feedback" ? (
                  <TETooltip
                    placement="right"
                    title={ele.name}
                    offset={[-15, 0]}
                  >
                    <NavLink
                      id="step-7"
                      className={`flex p-2 text-sm font-semibold w-full justify-center rounded-md group gap-x-3 hover:text-ipurple light:bg-gray-50 light:text-gray-700 hover:dark:bg-gray-700 hover:bg-gray-50 mb-8  `}
                      onClick={() =>
                        ele?.name === "Feedback"
                          ? handleShowReportPopup()
                          : handleNavClick()
                      }
                    >
                      <FeedbackIcon
                        className={`${getClassNamesKeypad(
                          "Feedback",
                          "null",
                        )} group-hover:!text-ipurple`}
                        height={"38px"}
                        width={"38px"}
                      />
                    </NavLink>
                  </TETooltip>
                ) : ele.name === "Support" ? (
                  <div
                    id="step-6"
                    className={`flex flex-col items-center hover:text-ipurple light:bg-gray-50 light:text-gray-700 hover:dark:bg-gray-700 hover:bg-gray-50 p-2 mr-2.5 text-sm font-semibold w-full justify-center rounded-md group gap-x-3 ${
                      showSupportModal
                        ? "text-ipurple !hover:text-ipurple hover:!bg-inherit"
                        : ""
                    }`}
                  >
                    <TETooltip
                      placement="right"
                      title={ele.name}
                      offset={[-5, 15]}
                    >
                      <HiOutlineSupport
                        className="text-black dark:text-white w-[28px]  h-[28px] svg-icon active:text-ipurple dark:hover:text-ipurple group-hover:text-ipurple"
                        style={{ strokeWidth: 1.3 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSupportModal((prev) => !prev);
                        }}
                      />
                    </TETooltip>

                    {showSupportModal && (
                      <div className="flex flex-col text-[12px]  border-[#ECEDF2] dark:border-darkModeBorder justify-center items-center w-[220px] h-[90px] divide-y-[1px] dark:divide-darkModeBorder bg-white dark:bg-darkModeBG shadow-xl rounded-lg absolute left-20 -mt-4">
                        <div onClick={(e) => e.stopPropagation()}></div>
                        <button
                          onClick={handleStartTour}
                          id="show-Tutorial"
                          className="flex items-center w-full gap-2 p-2 rounded-t-lg z-auto dark:hover:border-t-[1px] dark:hover:border-darkModeBorder hover:bg-lightBg dark:hover:bg-darkModeMain"
                        >
                          <Tutorial />
                          <p className="text-left text-black dark:text-white">
                            Show Tutorial
                          </p>
                        </button>
                        <NavLink
                          to="http://support.iallo.io"
                          className="flex items-center gap-2 p-2 rounded-b-lg dark:border-b-[1px] border-[#ECEDF2] dark:border-darkModeBorder text-black dark:text-white w-full hover:bg-lightBg dark:hover:bg-darkModeMain"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Agent />
                          <span>iAllo support center</span>
                        </NavLink>
                      </div>
                    )}
                  </div>
                ) : ele.name === "Settings" ? (
                  <TETooltip placement="right" title={ele.name}>
                    <NavLink
                      to={ele.link}
                      className={({ isActive }) =>
                        `flex p-2 mr-2 text-sm font-semibold w-full justify-center rounded-md group gap-x-3 ${
                          isActive || currentPath === ele.name
                            ? "text-ipurple"
                            : "hover:text-ipurple light:bg-gray-50 light:text-gray-700 hover:dark:bg-gray-700 hover:bg-gray-50"
                        }`
                      }
                      onClick={handleNavClick}
                    >
                      <SettingsIcon
                        className={`${getClassNames(
                          ele.link?.split("/")[1],
                          currentPath,
                        )} group-hover:!text-ipurple`}
                      />
                    </NavLink>
                  </TETooltip>
                ) : (
                  <NavLink
                    to={ele.link}
                    className={({ isActive }) =>
                      `flex p-2 mr-2 text-sm font-semibold w-full justify-center rounded-md group gap-x-3 ${
                        isActive || currentPath === ele.name
                          ? "text-ipurple"
                          : "hover:text-ipurple light:bg-gray-50 light:text-gray-700 hover:dark:bg-gray-700 hover:bg-gray-50 hover:!bg-[#EFF1FE]"
                      }`
                    }
                    onClick={() =>
                      ele?.name === "iAllo Extension"
                        ? handleCallExtension()
                        : handleNavClick()
                    }
                  >
                    <ele.icon
                      className={`${getClassNames(
                        ele.link?.split("/")[1],
                        currentPath,
                      )} group-hover:!text-ipurple`}
                    />
                  </NavLink>
                )}
              </div>
            ))}
          </ul>
        </nav>
      </div>
      {showReportPopup && <FeedbackPopup onClose={handleClose} />}
      {showCallPopup && <CallPopup onClose={handleClose} />}
    </div>
  );
}
